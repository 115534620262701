import styles from './article.module.css'
import ThisArticle from '../ThisArticle/thisarticle'
import { Route, Link, Routes } from "react-router-dom"

function Article(props) {
    //<ThisArticle id={props.id}/>
    const thumbnailURL = process.env.REACT_APP_API_URL + "/" + props.thumbnailURL
    return (
        <>
            <Link to={`/articles/${props.id}`}>
                <div className={styles.box}>
                    <img src={props.thumbnailURL}></img>
                    {props.title}
                </div>
            </Link>

        </>
    )
}

export default Article

