import styles from './credits.module.css'

function Credits(){
        //
    return(
        <section>
            Welcome to YumYum Credits
        </section>
    )
}

export default Credits